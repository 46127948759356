.radio {
    label {
        display: table-cell;
    }
    .form-group {
        display: table-cell;
        .form-control {
            display: inline-block;
        }
    }
}