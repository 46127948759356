.interactive-image {
    &-content {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        vertical-align: middle; 
        svg {
            display: block;
            position: absolute;
            top: 0;
            left: 0; 
        }
    }
}