@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .interactive-image-content {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle;
  }
  .interactive-image-content svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  input.form-control {
    line-height: 1 !important;
  }
  .app-bar .btn {
    vertical-align: auto !important;
  }
  .radio label {
    display: table-cell;
  }
  .radio .form-group {
    display: table-cell;
  }
  .radio .form-group .form-control {
    display: inline-block;
  }
}
/*# sourceMappingURL=mediprepare-theme-ie-gte-10.css.map */